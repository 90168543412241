var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "bgcenter" }),
    _c(
      "div",
      [
        _c("headbox", {
          attrs: {
            title: _vm.titleinfo,
            bgColor: "#fff",
            isborder: "true",
            initback: true,
            backIcon: _vm.backIcon
          },
          on: { initbackf: _vm.backpage }
        }),
        _vm.sellbool
          ? _c(
              "div",
              { staticClass: "centerbox" },
              [
                _c("img", {
                  staticClass: "icon",
                  attrs: { src: require("@/assets/images/pic_success@2x.png") }
                }),
                _c("div", { staticClass: "titlebox" }, [
                  _vm._v("定价提交成功")
                ]),
                _c("div", { staticClass: "infobox" }, [
                  _vm._v("我们将尽快为您审核,请留意短信通知")
                ]),
                _c(
                  "div",
                  { staticClass: "seedata", on: { click: _vm.seedata } },
                  [_vm._v("查看详情")]
                ),
                _c(
                  "el-button",
                  {
                    staticClass: "btnbox",
                    on: { click: _vm.pricingRecordslog }
                  },
                  [_vm._v("知道了 (" + _vm._s(_vm.number) + ")")]
                )
              ],
              1
            )
          : _vm._e(),
        _vm.mortgagebool
          ? _c(
              "div",
              { staticClass: "centerbox" },
              [
                _c("img", {
                  staticClass: "icon",
                  attrs: { src: require("@/assets/images/pic_success@2x.png") }
                }),
                _c("div", { staticClass: "titlebox" }, [
                  _vm._v("存料提交成功")
                ]),
                _c("div", { staticClass: "infobox" }, [
                  _vm._v("我们将尽快为您审核,请留意短信通知")
                ]),
                _c(
                  "div",
                  { staticClass: "seedata", on: { click: _vm.seedata } },
                  [_vm._v("查看详情")]
                ),
                _c(
                  "el-button",
                  {
                    staticClass: "btnbox",
                    on: { click: _vm.mortgageRecordslog }
                  },
                  [_vm._v("知道了 (" + _vm._s(_vm.number) + ")")]
                )
              ],
              1
            )
          : _vm._e(),
        _vm.face
          ? _c(
              "div",
              { staticClass: "centerbox" },
              [
                _c("img", {
                  staticClass: "icon",
                  attrs: { src: require("@/assets/images/pic_success@2x.png") }
                }),
                _c("div", { staticClass: "titlebox" }, [
                  _vm._v("资料提交成功")
                ]),
                _c("div", { staticClass: "infobox" }, [
                  _vm._v("我们将在1个工作日内将审核结果发送您的手机")
                ]),
                _c(
                  "el-button",
                  { staticClass: "btnbox", on: { click: _vm.seedata } },
                  [_vm._v("查看行情")]
                )
              ],
              1
            )
          : _vm._e(),
        _vm.usercerti
          ? _c(
              "div",
              { staticClass: "centerbox" },
              [
                _c("img", {
                  staticClass: "icon",
                  attrs: { src: require("@/assets/images/pic_success@2x.png") }
                }),
                _c("div", { staticClass: "titlebox" }, [
                  _vm._v("资料提交成功")
                ]),
                _c("div", { staticClass: "infobox" }, [
                  _vm._v("我们将在1个工作日内完成资料审核,请稍等")
                ]),
                _c(
                  "el-button",
                  { staticClass: "btnbox", on: { click: _vm.seedata } },
                  [_vm._v("查看行情")]
                )
              ],
              1
            )
          : _vm._e(),
        _vm.buybool
          ? _c(
              "div",
              { staticClass: "centerbox" },
              [
                _c("img", {
                  staticClass: "icon",
                  attrs: { src: require("@/assets/images/pic_success@2x.png") }
                }),
                _c("div", { staticClass: "titlebox" }, [
                  _vm._v("买料提交成功")
                ]),
                _c("div", { staticClass: "infobox" }, [
                  _vm._v("我们将尽快为您审核,请留意短信通知")
                ]),
                _c(
                  "div",
                  { staticClass: "seedata", on: { click: _vm.seedata } },
                  [_vm._v("查看详情")]
                ),
                _c(
                  "el-button",
                  {
                    staticClass: "btnbox",
                    on: { click: _vm.buypricingRecordslog }
                  },
                  [_vm._v("知道了 (" + _vm._s(_vm.number) + ")")]
                )
              ],
              1
            )
          : _vm._e(),
        _vm.jiesuan
          ? _c(
              "div",
              { staticClass: "centerbox" },
              [
                _c("img", {
                  staticClass: "icon",
                  attrs: { src: require("@/assets/images/pic_success@2x.png") }
                }),
                _c("div", { staticClass: "titlebox" }, [
                  _vm._v("您的结算单已确认")
                ]),
                _c("div", { staticClass: "infobox" }, [
                  _vm._v("飞速打款中，请留意银行短信通知")
                ]),
                _c(
                  "el-button",
                  { staticClass: "btnbox", on: { click: _vm.seedata } },
                  [_vm._v(" 查看行情 ")]
                )
              ],
              1
            )
          : _vm._e(),
        _vm.shangmen
          ? _c(
              "div",
              { staticClass: "centerbox" },
              [
                _c("img", {
                  staticClass: "icon",
                  attrs: { src: require("@/assets/images/pic_success@2x.png") }
                }),
                _c("div", { staticClass: "titlebox" }, [_vm._v("预约成功")]),
                _c("div", { staticClass: "infobox" }, [
                  _vm._v("取货人员正在飞速赶来")
                ]),
                _c(
                  "el-button",
                  { staticClass: "btnbox", on: { click: _vm.claimedit } },
                  [_vm._v("立即投保")]
                ),
                _c(
                  "div",
                  { staticClass: "footerbox ", on: { click: _vm.seedata } },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "btnstyle",
                        staticStyle: { width: "100%" }
                      },
                      [_vm._v("查看行情")]
                    )
                  ]
                )
              ],
              1
            )
          : _vm._e(),
        _vm.zhuce
          ? _c(
              "div",
              { staticClass: "centerbox" },
              [
                _c("img", {
                  staticClass: "icon",
                  attrs: { src: require("@/assets/images/pic_success@2x.png") }
                }),
                _c("div", { staticClass: "titlebox" }, [_vm._v("注册成功")]),
                _c("div", { staticClass: "infobox" }),
                _c(
                  "el-button",
                  { staticClass: "btnbox", on: { click: _vm.gologin } },
                  [_vm._v("前往登录")]
                )
              ],
              1
            )
          : _vm._e(),
        _vm.chongzhimima
          ? _c(
              "div",
              { staticClass: "centerbox" },
              [
                _c("img", {
                  staticClass: "icon",
                  attrs: { src: require("@/assets/images/pic_success@2x.png") }
                }),
                _c("div", { staticClass: "titlebox" }, [
                  _vm._v("重置密码成功")
                ]),
                _c("div", { staticClass: "infobox" }),
                _c(
                  "el-button",
                  { staticClass: "btnbox", on: { click: _vm.gologin } },
                  [_vm._v("前往登录")]
                )
              ],
              1
            )
          : _vm._e(),
        _vm.toubao
          ? _c(
              "div",
              { staticClass: "centerbox" },
              [
                _c("img", {
                  staticClass: "icon",
                  attrs: { src: require("@/assets/images/pic_success@2x.png") }
                }),
                _c("div", { staticClass: "titlebox" }, [
                  _vm._v("投保申请已提交")
                ]),
                _c("div", { staticClass: "infobox" }, [
                  _vm._v("稍后可在“我的保险”查看投保结果")
                ]),
                _c(
                  "el-button",
                  { staticClass: "btnbox", on: { click: _vm.myclaim } },
                  [_vm._v("查看保单")]
                ),
                _c(
                  "div",
                  { staticClass: "footerbox ", on: { click: _vm.seedata } },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "btnstyle",
                        staticStyle: { width: "100%" }
                      },
                      [_vm._v("返回行情")]
                    )
                  ]
                )
              ],
              1
            )
          : _vm._e(),
        _vm.Default
          ? _c(
              "div",
              { staticClass: "centerbox" },
              [
                _c("img", {
                  staticClass: "icon",
                  attrs: { src: require("@/assets/images/pic_success@2x.png") }
                }),
                _c("div", { staticClass: "titlebox" }, [
                  _vm._v("订单提交成功")
                ]),
                _c(
                  "div",
                  {
                    staticClass: "seedata",
                    on: { click: _vm.seeBreakContractData }
                  },
                  [_vm._v("查看详情")]
                ),
                _c(
                  "el-button",
                  {
                    staticClass: "btnbox",
                    on: { click: _vm.breakContractKnow }
                  },
                  [_vm._v("知道了 (" + _vm._s(_vm.number) + ")")]
                )
              ],
              1
            )
          : _vm._e(),
        _vm.buyDefault
          ? _c(
              "div",
              { staticClass: "centerbox" },
              [
                _c("img", {
                  staticClass: "icon",
                  attrs: { src: require("@/assets/images/pic_success@2x.png") }
                }),
                _c("div", { staticClass: "titlebox" }, [
                  _vm._v("订单提交成功")
                ]),
                _c(
                  "div",
                  {
                    staticClass: "seedata",
                    on: { click: _vm.seeBreakContractData }
                  },
                  [_vm._v("查看详情")]
                ),
                _c(
                  "el-button",
                  {
                    staticClass: "btnbox",
                    on: { click: _vm.buybreakContractKnow }
                  },
                  [_vm._v("知道了 (" + _vm._s(_vm.number) + ")")]
                )
              ],
              1
            )
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }